// //goole map styles
var styles = [
    {
        "featureType": "landscape",
        "stylers": [
            {
                "hue": "#FFBB00"
            },
            {
                "saturation": 43.400000000000006
            },
            {
                "lightness": 37.599999999999994
            },
            {
                "gamma": 1
            }
        ]
    },
    {
        "featureType": "road.highway",
        "stylers": [
            {
                "hue": "#FFC200"
            },
            {
                "saturation": -61.8
            },
            {
                "lightness": 45.599999999999994
            },
            {
                "gamma": 1
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "stylers": [
            {
                "hue": "#FF0300"
            },
            {
                "saturation": -100
            },
            {
                "lightness": 51.19999999999999
            },
            {
                "gamma": 1
            }
        ]
    },
    {
        "featureType": "road.local",
        "stylers": [
            {
                "hue": "#FF0300"
            },
            {
                "saturation": -100
            },
            {
                "lightness": 52
            },
            {
                "gamma": 1
            }
        ]
    },
    {
        "featureType": "water",
        "stylers": [
            {
                "hue": "#0078FF"
            },
            {
                "saturation": -13.200000000000003
            },
            {
                "lightness": 2.4000000000000057
            },
            {
                "gamma": 1
            }
        ]
    },
    {
        "featureType": "poi",
        "stylers": [
            {
                "hue": "#00FF6A"
            },
            {
                "saturation": -1.0989010989011234
            },
            {
                "lightness": 11.200000000000017
            },
            {
                "gamma": 1
            }
        ]
    }
];


//goole map
function initialize(lat, lang, id){
    var latlng = new google.maps.LatLng(lat,lang);

    var myOptions =
    {
        zoom: 16,
        center: latlng,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        scrollwheel: false,
        navigationControl: false,
        mapTypeControl: false,
        scaleControl: false,
    };

    map = new google.maps.Map(document.getElementById(id), myOptions);
    map.setOptions({styles: styles});
    map.panBy(0, -40);


    var myMarker = new google.maps.Marker(
    {
        position: latlng,
        map: map,
        icon: theme.googleMap.icon,
        title:"Forum"
    });
}

$(window).on('load', function(){
    
    if ( $("div").is("#map-canvas") ){
        initialize(theme.googleMap.lat, theme.googleMap.lng, "map-canvas");
        google.maps.event.addDomListener(window, "resize", function() {
           var center = map.getCenter();
           google.maps.event.trigger(map, "resize");
           map.setCenter(center);
        });
    }

    if ( $("div").is("#map-canvasRoute") ){
        
        var lat = theme.googleMap.lat;
        var lang = theme.googleMap.lang;

        var directionsDisplay = new google.maps.DirectionsRenderer(
            { 
                suppressMarkers: true,
                draggable: true,
                polylineOptions: {
                    strokeColor: "#80d13b"
                }
            }
        );
        
        var directionsService = new google.maps.DirectionsService();
        var geocoder = new google.maps.Geocoder;
        var infowindow = new google.maps.InfoWindow;

        var routeGo = $("#routeGo");
        var routeTo = $("#routeTo");
        var routeFrom = $("#routeFrom");

        var inputFrom = document.getElementById('routeFrom');
        var inputTo = document.getElementById('routeTo');
        var request;

        var latlng = new google.maps.LatLng(lat, lang);
        var myOptions = {
            zoom: 16,
            center: latlng,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            scrollwheel: false,
            navigationControl: false,
            mapTypeControl: false,
            scaleControl: false,
        };
        
        map = new google.maps.Map(document.getElementById("map-canvasRoute"), myOptions);
        map.setOptions({styles: styles});
        map.panBy(0, -60);
        directionsDisplay.setMap(map);

        var myMarker = new google.maps.Marker({
            position: latlng,
            map: map,
            icon: theme.googleMap.icon,
        });
        
        google.maps.event.addListener(myMarker , 'click', function() {
            window.location.href = theme.googleMap.link;
        });
        
        var autocompleteFrom = new google.maps.places.Autocomplete(inputFrom);
        var autocompleteTo = new google.maps.places.Autocomplete(inputTo);
        
        routeGo.on("click", function() { calcRoute(); });

        $('.map-search-location').on('click', function () {
            // Try HTML5 geolocation.
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    var pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                    routeFrom.val(position.coords.latitude+","+position.coords.longitude);
                    /*infowindow.setPosition(pos);
                    infowindow.setContent('Jesteś tutaj');
                    infowindow.open(map);*/
                    map.setCenter(pos);
                }, function () {
                    handleLocationError(true, infowindow, map.getCenter());
                });
            } else {
                // Browser doesn't support Geolocation
                handleLocationError(false, infowindow, map.getCenter());
            }
        });


        google.maps.event.addDomListener(window, "resize", function() {
            var center = map.getCenter();
            google.maps.event.trigger(map, "resize");
            map.setCenter(center);
        });

        function makeMarker( position, icon, title ) {
            new google.maps.Marker({
                position: position,
                map: map,
                icon: icon,
                title: title
            });
        }


        function calcRoute() {
            console.log('callRoute');
            request = {
                origin: routeFrom.val(),
                destination: routeTo.val(),
                travelMode: $(".map-item-route-types input[type='radio']:checked").val(),
            };
            directionsService.route(request, function(response, status) {
                if (status == google.maps.DirectionsStatus.OK) {
                    directionsDisplay.setDirections(response);
                    var leg = response.routes[ 0 ].legs[ 0 ];
                    makeMarker( leg.start_location, icons.start, "title" );
                    makeMarker( leg.end_location, icons.end, 'title' );
                }
            });
        }
    }
});